import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["checkbox", "previewPanel", "messagePanel"]
  messagePanelTargetConnected() {
    this.updatePreview()
  }
  updatePreview() {
    if (this.hasPreviewPanelTarget && this.hasMessagePanelTarget) {
      let strings = []
      this.checkboxTargets.forEach((checkbox) => {
        if (checkbox.checked) {
          let parent = checkbox.closest('.form-check')
          let label = parent.querySelector('label')
          strings.push(label.textContent)
        }
      })
      if (strings.length > 0) {
        this.messagePanelTarget.textContent = strings.join(', ')
        this.previewPanelTarget.classList.add('show')
      } else {
        this.messagePanelTarget.textContent = ''
        this.previewPanelTarget.classList.remove('show')
      }
    }
  }
}