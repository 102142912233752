import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = { url: String, interval: Number }

  connect() {
    this.load()

    if (this.hasIntervalValue) {
      this.startRefreshing()
    }
  }
  disconnect() {
    this.stopRefreshing()
  }

  load() {
    this.element.setAttribute('src', this.urlValue)
  }

  startRefreshing() {
    this.refreshTimer = setInterval(() => {
      this.load()
    }, this.intervalValue)
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }
  }
}