import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["button", "spinner"];

  reveal() {
    if (this.hasButtonTarget) {
      this.buttonTarget.classList.remove("d-none");
    }
  }
  submit() {
    if (this.form() && !this.form().reportValidity()) {
      return false
    }
    if (this.hasSpinnerTarget) {
      this.spinnerTarget.classList.remove("d-none");
    } else {
      this.element.classList.remove("is-updating")
    }
    if (this.hasButtonTarget) {
      this.buttonTarget.click();
    }
  }

  form() {
    if (this.hasButtonTarget) {
      return this.buttonTarget.closest("form")
    }
  }
}

