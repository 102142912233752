import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = { folderId: { type: Number }, fieldSelector: { type: String, default: "" } }

  select(evt) {
    evt.preventDefault()
    const field = document.querySelector(this.fieldSelectorValue)
    if (field) {
      field.value = this.folderIdValue.toString()
      this.element.classList.add("bg-success")
      this.element.classList.add("bg-opacity-25")
      this.dispatch("selected", { detail: { folderId: this.folderIdValue } })
    }
  }
}
