import { Controller } from "@hotwired/stimulus"
import { Modal, Popover, Tooltip } from "bootstrap"

export default class extends Controller {

  attach(event) {
    document.querySelectorAll('[data-bs-toggle="tooltip"]').forEach(el => {
      new Tooltip(el);
    })
    document.querySelectorAll('[data-bs-toggle="popover"]').forEach(el => {
      new Popover(el);
    })
    document.querySelectorAll('[data-toggle="modal"]').forEach(el => {
      el.addEventListener('click', evt => {
        evt.preventDefault();
        let target = document.getElementById(evt.target.dataset.target);
        let modal = new Modal(target);
        modal.show();
      })
    })
  }
}