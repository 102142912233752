import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["field", "panel"]
  static values = { visible: Object }

  fieldValueChanged(event) {
    this.showOrHidePanel()
  }

  showOrHidePanel() {
    if (this.hasFieldTarget) {
      const value = this.fieldTarget.value
      const showPanel = (Object.keys(this.visibleValue).indexOf(value) > -1)
      const panelName = this.visibleValue[value]
      this.panelTargets.forEach((panel) => {
        if ((panel.dataset.name == panelName) && showPanel) {
          panel.classList.remove("d-none")
        } else {
          panel.classList.add("d-none")
        }
      })
    }
  }

  panelTargetConnected(panel) {
    this.showOrHidePanel()
  }

  fieldTargetConnected(field) {
    this.changeHandler = this.fieldValueChanged.bind(this)
    field.addEventListener("change", this.changeHandler)
    this.showOrHidePanel()
  }

}

