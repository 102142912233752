import { Controller } from "@hotwired/stimulus"
import { loadStripe } from '@stripe/stripe-js/pure';


export default class extends Controller {
  static targets = ["payment", "message", "spinner"]
  static values = { key: String, url: String, completion: String }

  connect() {
    this.stripe = null
    this.elements = null
    this.initialiseStripe()
  }

  async initialiseStripe() {
    this.stripe = await loadStripe(this.keyValue);
    const response = await fetch(this.urlValue, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
    })
    const { clientSecret } = await response.json()

    const appearance = { theme: 'stripe' }
    this.elements = this.stripe.elements({ appearance, clientSecret })
    const paymentElement = this.elements.create("payment", { layout: "tabs" });
    paymentElement.mount(this.paymentTarget);
  }

  requestPayment(evt) {
    evt.preventDefault()
    this.doPaymentRequest()
  }

  async doPaymentRequest() {
    this.spinnerTarget.style.display = "block"
    const elements = this.elements
    const { error } = await this.stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: this.completionValue
      },
    });

    if (error.type === "card_error" || error.type === "validation_error") {
      this.messageTarget.innerText = error.message
    } else {
      this.messageTarget.innerText = "There was an error while processing payment"
    }
    this.spinnerTarget.style.display = "none"
  }
}
