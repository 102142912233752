import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["matcher", "definition"]

  updateDefinition() {
    if (this.hasDefinitionTarget) {
      let definition = ""
      const matchersInOrder = this.matcherTargets.sort((a, b) => {
        const ex = /match_(\d+)/
        const aId = parseInt(a.id.match(ex))
        const bId = parseInt(b.id.match(ex))
        return aId - bId
      })
      matchersInOrder.forEach(select => {
        const value = select.options[select.selectedIndex].value
        definition = definition + value + "\n"
      })
      this.definitionTarget.value = definition
      this.dispatch("change")
    }
  }
}