import { Controller } from "@hotwired/stimulus"
import { Chart, registerables } from 'chart.js';
Chart.register(...registerables);

export default class extends Controller {
  static values = { labels: String, data: String, colour: String, datasetTitle: String, chartTitle: String }

  connect() {
    const labelsArray = this.labelsValue.split(',')
    const dataArray = this.dataValue.split(',').map(v => parseInt(v))
    const data = {
      labels: labelsArray,
      datasets: [{
        label: this.datasetTitleValue,
        data: dataArray,
        borderColor: this.colourValue,
        backgroundColor: this.colourValue,
        tension: 0.2,
      }],
    }
    const config = {
      type: 'line',
      data: data,
      options: {
        responsive: true,
        plugins: {
          legend: {
            position: 'top',
          },
          title: {
            display: false,
          }
        },
        scales: {
          x: {
            grid: {
              display: false,
              drawBorder: false,
              drawOnChartArea: false,
              drawTicks: true,
            },
          },
          y: {
            type: 'linear',
            grid: {
              display: false,
              drawBorder: false,
              drawOnChartArea: false,
              drawTicks: true,
            },
          },
        },
      }
    }
    const ctx = this.element.getContext('2d');
    new Chart(ctx, config);
  }
}