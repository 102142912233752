import { Controller } from "@hotwired/stimulus"
export default class extends Controller {
  static targets = ['selectable']
  toggleFromCheckbox(event) {
    const checkbox = event.target
    if (checkbox.checked) {
      this.selectAll(event)
    } else {
      this.selectNone(event)
    }
  }
  selectAll(event) {
    this.selectableTargets.forEach((element, index) => {
      if (!element.disabled) element.checked = true
    })
  }
  selectNone(event) {
    this.selectableTargets.forEach((element, index) => {
      if (!element.disabled) element.checked = false
    })
  }
}