import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["control", "button", "errorMessage"]
  static values = { useDefault: Boolean }

  connect() {
    this.showOrHideControls()
  }

  controlTargetConnected() {
    this.showOrHideControls()
  }

  buttonTargetConnected() {
    this.showOrHideControls()
  }

  errorMessageTargetConnected() {
    this.showOrHideControls()
  }

  showButton() {
    this.buttonTargets.forEach(button => {
      button.classList.remove('d-none')
    })
  }

  hideButton() {
    this.buttonTargets.forEach(button => {
      button.classList.add('d-none')
    })
  }

  showError() {
    if (this.hasErrorMessageTarget) {
      this.errorMessageTarget.classList.remove('d-none')
    }
  }

  hideError() {
    if (this.hasErrorMessageTarget) {
      this.errorMessageTarget.classList.add('d-none')
    }
  }

  showOrHideControls() {
    if (this.useDefaultValue || (this.hasControlTarget && this.controlTarget.value != "")) {
      this.showButton()
      this.hideError()
    } else {
      this.hideButton()
      this.showError()
    }

  }

  update(evt) {
    this.showOrHideControls()
  }
}