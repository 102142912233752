import Sortable from '@stimulus-components/sortable'
import { patch } from "@rails/request.js"

export default class extends Sortable {

  async onUpdate({ item, newIndex }) {
    if (!item.dataset.sortableUpdateUrl) return

    const param = this.resourceNameValue ? `${this.resourceNameValue}[${this.paramNameValue}]` : this.paramNameValue

    const data = new FormData()
    data.append(param, newIndex + 1)

    const response = await patch(item.dataset.sortableUpdateUrl, { body: data, responseKind: this.responseKindValue })
    const html = await response.text
    this.element.innerHTML = html
  }

}