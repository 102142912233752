import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["switch", "field"]

  fieldTargetConnected(field) {
    field.classList.add("d-none")
  }

  fieldTargetDisconnected(field) {
    field.classList.remove("d-none")
  }

  switchTargetConnected(element) {
    this.onChange = this.switchChanged.bind(this)
    element.addEventListener("sl-change", this.onChange)
  }

  switchTargetDisconnected(element) {
    element.removeEventListener("sl-change", this.onChange)
    this.onChange = null
  }

  switchChanged(event) {
    if (this.hasFieldTarget) {
      this.fieldTarget.checked = event.target.checked
    }
  }

}

