import { Controller } from "@hotwired/stimulus"
import dragula from "dragula"

// Draggable objects declare themselves as a file/folder/project target
// "Source" elements should declare the ID of their respective objects using data parameters
//
// data-document-id
// data-folder-id
// data-project-id
//
// When a source is dropped onto a target element, the target element uses its own data parameters to identify a link element
//
// data-move-file-into-folder-link
// data-move-folder-into-folder-link
// data-move-folder-to-top-level-link
//
// The target element will find this link element, then set a property on it to the relevant ID parameter of the source element
//
// link.documentId
// link.folderId
// link.projectId
//
// The link element will then dispatch a click event, which will trigger the relevant action on the server.  
// The click handler should examine the link's properties to determine which object to move where.

export default class extends Controller {
  static targets = ["file", "folder", "project"]

  initialize() {
    this.dragula = dragula({
      copy: false,
      revertOnSpill: true,
      accepts: this.canAcceptElement.bind(this)
    })
    this.dragula.on("drop", this.dropElement.bind(this))
  }

  fileTargetConnected(element) {
    this.addContainer(element)
  }

  fileTargetDisconnected(element) {
    this.removeContainer(element)
  }

  folderTargetConnected(element) {
    this.addContainer(element)
  }

  folderTargetDisconnected(element) {
    this.removeContainer(element)
  }

  projectTargetConnected(element) {
    this.addContainer(element)
  }

  projectTargetDisconnected(element) {
    this.removeContainer(element)
  }

  addContainer(element) {
    this.dragula.containers.push(element)
  }

  removeContainer(element) {
    const index = this.dragula.containers.indexOf(element)
    if (index > -1) {
      this.dragula.containers.splice(index, 1)
    }
  }

  isFile(element) {
    return this.fileTargets.indexOf(element) > -1
  }

  isFolder(element) {
    return this.folderTargets.indexOf(element) > -1
  }

  isProject(element) {
    return this.projectTargets.indexOf(element) > -1
  }

  canAcceptElement(element, target, source, sibling) {
    if (this.isProject(source) || this.isFile(target)) {
      return false
    } else if (this.isFile(source) && this.isFolder(target)) {
      return true
    } else if (this.isFolder(source) && this.isFolder(target)) {
      return true
    } else if (this.isFolder(source) && this.isProject(target)) {
      return true
    } else {
      return false
    }
  }

  dropElement(element, target, source, sibling) {
    if (this.isFile(source) && this.isFolder(target)) {
      this.moveFileIntoFolder(element, source, target)
    } else if (this.isFolder(source) && this.isFolder(target)) {
      this.moveFolderIntoFolder(element, source, target)
    } else if (this.isFolder(source) && this.isProject(target)) {
      this.moveFolderToTopLevel(element, source, target)
    } else {
      return null
    }
  }

  async moveFileIntoFolder(element, file, destination) {
    const link = document.getElementById(destination.dataset.moveFileIntoFolderLink)
    if (link) {
      link.documentId = file.dataset.documentId
      destination.addEventListener("animationend", () => {
        link.dispatchEvent(new MouseEvent("click"))
      })
      element.classList.add("d-none")
      destination.classList.add("is-eating")
    }
  }

  async moveFolderIntoFolder(element, folder, destination) {
    const link = document.getElementById(destination.dataset.moveFolderIntoFolderLink)
    if (link) {
      link.folderId = folder.dataset.folderId
      destination.addEventListener("animationend", () => {
        link.dispatchEvent(new MouseEvent("click"))
      })
      element.classList.add("d-none")
      const treeItem = folder.closest("sl-tree-item")
      if (treeItem) {
        treeItem.remove()
      }
      destination.classList.add("is-eating")
    }
  }

  async moveFolderToTopLevel(element, folder, project) {
    const link = document.getElementById(project.dataset.moveFolderToTopLevelLink)
    if (link) {
      link.folderId = folder.dataset.folderId
      project.addEventListener("animationend", () => {
        link.dispatchEvent(new MouseEvent("click"))
      })
      element.classList.add("d-none")
      const treeItem = folder.closest("sl-tree-item")
      if (treeItem) {
        treeItem.remove()
      }
      project.classList.add("is-eating")
    }
  }

}