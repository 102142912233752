import { Controller } from "@hotwired/stimulus"
import { DirectUpload } from "@rails/activestorage";
import { post } from "@rails/request.js";

export default class extends Controller {
  static targets = ["errors", "files"]
  static values = { errorUrl: String, url: String, files: Array }

  connect() {
    const fileInput = document.createElement('input');
    fileInput.type = 'file';
    fileInput.multiple = true;
    fileInput.style.display = 'none';

    this.element.appendChild(fileInput)
    this.fileInput = fileInput

    this.element.addEventListener('dragover', this.dragOver.bind(this))
    this.element.addEventListener('dragleave', this.dragLeave.bind(this))
    this.element.addEventListener('drop', this.dropFiles.bind(this))
    this.element.addEventListener('click', this.clickToSelect.bind(this))
    this.fileInput.addEventListener('change', this.selectionChanged.bind(this))
  }

  disconnect() {
    this.fileInput = null
  }

  filesValueChanged() {
    if (this.hasFilesTarget) {
      this.filesTarget.innerHTML = this.filesValue.join(", ")
    }
    if (this.filesValue.length > 0) {
      this.element.classList.add('is-updating')
    } else {
      this.element.classList.remove('is-updating')
    }
  }

  dragOver(event) {
    event.preventDefault()
    this.element.classList.add('is-eating')
  }

  dragLeave(event) {
    this.element.classList.remove('is-eating')
  }

  dropFiles(event) {
    event.preventDefault();
    this.handleFiles(event.dataTransfer.files)
  }

  clickToSelect(event) {
    this.fileInput.click()
  }

  selectionChanged(event) {
    this.handleFiles(event.target.files)
    this.fileInput.value = '' // Reset input value to allow re-selection of the same file(s)
  }

  async handleFiles(files) {
    for (const file of files) {
      this.filesValue = [file.name, ...this.filesValue]
      this.uploadFile(file)
    }
  }

  async uploadFile(file) {
    const upload = new DirectUpload(file, '/rails/active_storage/direct_uploads')
    const uploadUrl = this.urlValue
    upload.create((error, blob) => {
      if (!error) {
        post(uploadUrl, {
          body: { filename: blob.filename, signed_blob_id: blob.signed_id },
          contentType: "application/json",
          responseKind: "json",
        })
        const index = this.filesValue.indexOf(file.name)
        if (index > -1) { this.filesValue = this.filesValue.toSpliced(index, 1) }
      } else {
        console.log(error)
      }
    });
  }

  async recordError(error, message) {
    if (this.hasErrorsTarget) {
      this.errorsTarget.classList.add('show')
      const p = document.createElement('p')
      this.errorsTarget.append(message, p)
    }
  }

}
