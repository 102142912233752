import { Controller } from "@hotwired/stimulus"
import { Chart, registerables } from 'chart.js';
Chart.register(...registerables);

export default class extends Controller {
  static values = { labels: Array, datasets: Array }

  connect() {
    const data = {
      labels: this.labelsValue,
      datasets: this.datasetsValue,
    }
    const config = {
      type: 'bar',
      data: data,
      options: {
        responsive: true,
        plugins: {
          legend: {
            position: 'top',
          },
          title: {
            display: false,
          }
        },
        scales: {
          x: {
            stacked: true,
          },
          y: {
            stacked: true,
          },
        },
      }
    }
    const ctx = this.element.getContext('2d');
    new Chart(ctx, config);
  }
}