// pdf-comparison.js - Custom Element for PDF Comparison
// This module defines a Web Component that compares two PDFs and highlights differences

class PdfComparison extends HTMLElement {
  constructor() {
    super();
    this.attachShadow({ mode: 'open' });

    // Initialize properties
    this._pdf1Url = '';
    this._pdf2Url = '';
    this._pdf1Name = '';
    this._pdf2Name = '';
    this._isLoading = false;
    this._hasCompared = false;
    this._error = null;

    // Setup initial DOM
    this._render();

    // Bind methods
    this._comparePDFs = this._comparePDFs.bind(this);
  }

  // Define observed attributes for the element
  static get observedAttributes() {
    return ['pdf1-url', 'pdf2-url', 'pdf1-name', 'pdf2-name', 'auto-compare'];
  }

  // Handle attribute changes
  attributeChangedCallback(name, oldValue, newValue) {
    if (oldValue === newValue) return;

    switch (name) {
      case 'pdf1-url':
        this._pdf1Url = newValue;
        break;
      case 'pdf2-url':
        this._pdf2Url = newValue;
        break;
      case 'pdf1-name':
        this._pdf1Name = newValue;
        break;
      case 'pdf2-name':
        this._pdf2Name = newValue;
        break;
    }

    this._updateView();
  }

  // Element connected to DOM
  connectedCallback() {
    // Add event listener to compare button
    const compareButton = this.shadowRoot.getElementById('compare-btn');
    if (compareButton) {
      compareButton.addEventListener('click', this._comparePDFs);
    }

    // Initial state update
    this._updateView();

    // Auto-compare if both URLs are set and auto-compare is enabled
    if (this._pdf1Url && this._pdf2Url) {
      this._comparePDFs();
    }
  }

  // Getters and setters for properties
  get pdf1Url() {
    return this._pdf1Url;
  }

  set pdf1Url(value) {
    this.setAttribute('pdf1-url', value);
  }

  get pdf2Url() {
    return this._pdf2Url;
  }

  set pdf2Url(value) {
    this.setAttribute('pdf2-url', value);
  }

  get pdf1Name() {
    return this._pdf1Name;
  }

  set pdf1Name(value) {
    this.setAttribute('pdf1-name', value);
  }

  get pdf2Name() {
    return this._pdf2Name;
  }

  set pdf2Name(value) {
    this.setAttribute('pdf2-name', value);
  }

  // Create styles for the component
  _getStyles() {
    return `
      :host {
        display: block;
        font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
        width: 100%;
        box-sizing: border-box;
      }
      
      * {
        box-sizing: border-box;
      }
      
      .container {
        max-width: min(95vw, 1000px);
        margin: 0 auto;
        padding: 20px;
        line-height: 1.6;
      }
      
      h1, h2, h3, h4 {
        margin-top: 0;
      }
      
      button {
        display: block;
        width: 200px;
        margin: 20px auto;
        padding: 12px;
        background-color: #4CAF50;
        color: white;
        border: none;
        border-radius: 4px;
        font-size: 16px;
        cursor: pointer;
        transition: background-color 0.3s;
      }
      
      button:hover {
        background-color: #45a049;
      }
      
      button:disabled {
        background-color: #cccccc;
        cursor: not-allowed;
      }
      
      .pdf-info {
        display: flex;
        justify-content: space-between;
        gap: 20px;
        margin-bottom: 20px;
      }
      
      .pdf-item {
        flex: 1;
        padding: 15px;
        border: 1px solid #ddd;
        border-radius: 4px;
      }
      
      .pdf-display {
        width: 100%;
        max-width: 100vw;
        margin: 30px 0;
        text-align: center;
      }
      
      .canvas-container {
        position: relative;
        border: 1px solid #ddd;
        overflow: auto;
        max-height: 600px;
        width: 100%;
        max-width: 95vw;
      }
      
      canvas {
        display: block;
        margin: 0 auto;
      }
      
      #diff-canvas {
        max-width: 100%;
        height: auto;
      }
      
      #diff-view {
        position: relative;
        margin-top: 30px;
        margin-bottom: 30px;
        text-align: center;
        width: 100%;
      }
      
      .highlight {
        position: absolute;
        background-color: rgba(255, 0, 0, 0.3);
        border: 1px solid rgba(255, 0, 0, 0.5);
        pointer-events: none;
      }
      
      .loading {
        text-align: center;
        padding: 20px;
        font-style: italic;
        color: #666;
      }
      
      .error {
        color: #d9534f;
        text-align: center;
        padding: 10px;
        border: 1px solid #d9534f;
        border-radius: 4px;
        margin: 20px 0;
      }
      
      .success {
        color: #5cb85c;
        text-align: center;
        padding: 10px;
        border: 1px solid #5cb85c;
        border-radius: 4px;
        margin: 20px 0;
      }
    `;
  }

  // Create the HTML structure for the component
  _render() {
    this.shadowRoot.innerHTML = `
      <style>${this._getStyles()}</style>
      <div class="container">
        <div id="pdf-viewers" style="display: none;">
          <div id="diff-view">
            <div class="canvas-container">
              <canvas id="diff-canvas"></canvas>
            </div>
          </div>
          
          <div class="pdf-display">
            <h3>${this._pdf1Name ? this._pdf1Name : ''}</h3>
            <div class="canvas-container" id="pdf1-container"></div>
          </div>
          
          <div class="pdf-display">
            <h3>${this._pdf2Name ? this._pdf2Name : ''}</h3>
            <div class="canvas-container" id="pdf2-container"></div>
          </div>
        </div>
      </div>
    `;
  }

  // Update the view based on current state
  _updateView() {
    // Update visibility of PDF viewers
    const pdfViewers = this.shadowRoot.getElementById('pdf-viewers');
    if (pdfViewers) {
      pdfViewers.style.display = this._hasCompared && !this._error ? 'block' : 'none';
    }
  }

  // Compare the PDFs
  async _comparePDFs() {
    // Check if PDFs are available
    if (!this._pdf1Url || !this._pdf2Url) {
      this._error = 'Please provide URLs for both PDFs';
      this._updateView();
      return;
    }

    // Verify pdfjsLib is available
    if (typeof pdfjsLib === 'undefined') {
      this._loadPdfJs().catch(error => {
        this._error = 'Failed to load PDF.js library. Please include PDF.js in your project.';
        this._isLoading = false;
        this._updateView();
      });
      return;
    }

    // Set loading state
    this._isLoading = true;
    this._error = null;
    this._hasCompared = false;
    this._updateView();

    try {
      // Fetch both PDFs
      const [pdf1ArrayBuffer, pdf2ArrayBuffer] = await Promise.all([
        this._fetchPdf(this._pdf1Url),
        this._fetchPdf(this._pdf2Url)
      ]);

      const pdf1Data = new Uint8Array(pdf1ArrayBuffer);
      const pdf2Data = new Uint8Array(pdf2ArrayBuffer);

      // Load both PDFs
      const [pdf1, pdf2] = await Promise.all([
        pdfjsLib.getDocument(pdf1Data).promise,
        pdfjsLib.getDocument(pdf2Data).promise
      ]);

      // Check number of pages
      const resultElement = this.shadowRoot.getElementById('comparison-result');
      if (pdf1.numPages !== pdf2.numPages) {
        resultElement.innerHTML = `<div class="error">PDFs have different number of pages (PDF 1: ${pdf1.numPages} pages, PDF 2: ${pdf2.numPages} pages). Showing comparison of first page only.</div>`;
      }

      // For simplicity, we'll just compare the first page of each PDF
      const [page1, page2] = await Promise.all([
        pdf1.getPage(1),
        pdf2.getPage(1)
      ]);

      // Get page dimensions (we'll use the larger of the two for the canvas)
      const viewport1 = page1.getViewport({ scale: 1.5 });
      const viewport2 = page2.getViewport({ scale: 1.5 });

      const width = Math.max(viewport1.width, viewport2.width);
      const height = Math.max(viewport1.height, viewport2.height);

      // Clear previous content
      const pdf1Container = this.shadowRoot.getElementById('pdf1-container');
      const pdf2Container = this.shadowRoot.getElementById('pdf2-container');

      pdf1Container.innerHTML = '';
      pdf2Container.innerHTML = '';

      // Create canvases for both PDFs
      const canvas1 = document.createElement('canvas');
      const ctx1 = canvas1.getContext('2d');
      canvas1.width = width;
      canvas1.height = height;

      const canvas2 = document.createElement('canvas');
      const ctx2 = canvas2.getContext('2d');
      canvas2.width = width;
      canvas2.height = height;

      // Render both PDFs
      await Promise.all([
        page1.render({ canvasContext: ctx1, viewport: viewport1 }).promise,
        page2.render({ canvasContext: ctx2, viewport: viewport2 }).promise
      ]);

      // Add canvases to the DOM
      pdf1Container.appendChild(canvas1);
      pdf2Container.appendChild(canvas2);

      // Get the diff canvas
      const diffCanvas = this.shadowRoot.getElementById('diff-canvas');
      const diffCtx = diffCanvas.getContext('2d');

      // Create a diff image
      diffCanvas.width = width;
      diffCanvas.height = height;
      diffCtx.clearRect(0, 0, width, height);

      // Set the display style for the canvases
      // The diff canvas can scale down for display
      const maxWidth = window.innerWidth * 0.95;
      if (width > maxWidth) {
        diffCanvas.style.width = '95%';
      }

      // Get image data from both canvases
      const imageData1 = ctx1.getImageData(0, 0, width, height);
      const imageData2 = ctx2.getImageData(0, 0, width, height);
      const diffImageData = diffCtx.createImageData(width, height);

      // Compare pixel by pixel
      let hasDifferences = false;
      for (let i = 0; i < imageData1.data.length; i += 4) {
        // Get pixel values from both images
        const r1 = imageData1.data[i];
        const g1 = imageData1.data[i + 1];
        const b1 = imageData1.data[i + 2];

        const r2 = imageData2.data[i];
        const g2 = imageData2.data[i + 1];
        const b2 = imageData2.data[i + 2];

        // Calculate difference using a threshold
        const threshold = 30; // Adjust this value to control sensitivity
        const diff = Math.abs(r1 - r2) + Math.abs(g1 - g2) + Math.abs(b1 - b2);

        if (diff > threshold) {
          // Highlight difference in red
          diffImageData.data[i] = 255; // R
          diffImageData.data[i + 1] = 0; // G
          diffImageData.data[i + 2] = 0; // B
          diffImageData.data[i + 3] = 150; // A (semi-transparent)
          hasDifferences = true;
        } else {
          // Keep original content with reduced opacity
          diffImageData.data[i] = Math.floor((r1 + r2) / 2);
          diffImageData.data[i + 1] = Math.floor((g1 + g2) / 2);
          diffImageData.data[i + 2] = Math.floor((b1 + b2) / 2);
          diffImageData.data[i + 3] = 100; // A (more transparent)
        }
      }

      // Put the diff image data on the canvas
      diffCtx.putImageData(diffImageData, 0, 0);

      // Also highlight differences on the original PDFs
      this._highlightDifferences(canvas1, canvas2, pdf1Container, pdf2Container, imageData1, imageData2);

      // Update state and show the result
      this._isLoading = false;
      this._hasCompared = true;

      if (resultElement) {
        // Only set this message if we haven't already set one (e.g., for different page counts)
        if (!resultElement.innerHTML.includes('different number of pages')) {
          resultElement.innerHTML = hasDifferences ?
            '<div style="text-align: center; margin: 20px 0; font-weight: bold; color: #d9534f;">Differences detected and highlighted in red</div>' :
            '<div style="text-align: center; margin: 20px 0; font-weight: bold; color: #5cb85c;">No significant differences detected</div>';
        }
      }

      this._updateView();

      // Dispatch a custom event
      this.dispatchEvent(new CustomEvent('comparison-complete', {
        detail: { hasDifferences }
      }));

    } catch (error) {
      console.error('Error comparing PDFs:', error);
      this._error = error.message || 'Failed to compare PDFs';
      this._isLoading = false;
      this._hasCompared = false;
      this._updateView();

      // Dispatch error event
      this.dispatchEvent(new CustomEvent('comparison-error', {
        detail: { error: this._error }
      }));
    }
  }

  // Helper function to fetch a PDF
  async _fetchPdf(url) {
    try {
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error(`Failed to fetch PDF: ${response.statusText}`);
      }
      return await response.arrayBuffer();
    } catch (error) {
      throw new Error(`Failed to fetch PDF from ${url}: ${error.message}`);
    }
  }

  // Helper function to highlight differences
  _highlightDifferences(canvas1, canvas2, pdf1Container, pdf2Container, imageData1, imageData2, threshold = 30) {
    const width = canvas1.width;
    const height = canvas1.height;

    // Clear any existing highlights
    pdf1Container.querySelectorAll('.highlight').forEach(el => el.remove());
    pdf2Container.querySelectorAll('.highlight').forEach(el => el.remove());

    // Calculate the canvas scale factor - using 1 for original size
    const canvas1Scale = 1;
    const canvas2Scale = 1;

    // Create overlay divs for differences
    for (let y = 0; y < height; y += 10) {
      for (let x = 0; x < width; x += 10) {
        let hasDiff = false;

        // Check a 10x10 pixel block for differences
        blockLoop: for (let dy = 0; dy < 10 && y + dy < height; dy++) {
          for (let dx = 0; dx < 10 && x + dx < width; dx++) {
            const i = 4 * ((y + dy) * width + (x + dx));

            const r1 = imageData1.data[i];
            const g1 = imageData1.data[i + 1];
            const b1 = imageData1.data[i + 2];

            const r2 = imageData2.data[i];
            const g2 = imageData2.data[i + 1];
            const b2 = imageData2.data[i + 2];

            const diff = Math.abs(r1 - r2) + Math.abs(g1 - g2) + Math.abs(b1 - b2);

            if (diff > threshold) {
              hasDiff = true;
              break blockLoop;
            }
          }
        }

        if (hasDiff) {
          // Create highlight for PDF 1
          const highlight1 = document.createElement('div');
          highlight1.className = 'highlight';
          highlight1.style.left = (x * canvas1Scale) + 'px';
          highlight1.style.top = (y * canvas1Scale) + 'px';
          highlight1.style.width = (10 * canvas1Scale) + 'px';
          highlight1.style.height = (10 * canvas1Scale) + 'px';
          pdf1Container.appendChild(highlight1);

          // Create highlight for PDF 2
          const highlight2 = document.createElement('div');
          highlight2.className = 'highlight';
          highlight2.style.left = (x * canvas2Scale) + 'px';
          highlight2.style.top = (y * canvas2Scale) + 'px';
          highlight2.style.width = (10 * canvas2Scale) + 'px';
          highlight2.style.height = (10 * canvas2Scale) + 'px';
          pdf2Container.appendChild(highlight2);
        }
      }
    }
  }

  // Load PDF.js if not available
  async _loadPdfJs() {
    if (typeof pdfjsLib !== 'undefined') {
      return Promise.resolve();
    }

    return new Promise((resolve, reject) => {
      // Create script element for PDF.js
      const script = document.createElement('script');
      script.src = 'https://cdnjs.cloudflare.com/ajax/libs/pdf.js/3.4.120/pdf.min.js';
      script.onload = () => {
        // Create script element for PDF.js worker
        const workerScript = document.createElement('script');
        workerScript.onload = () => {
          pdfjsLib.GlobalWorkerOptions.workerSrc = 'https://cdnjs.cloudflare.com/ajax/libs/pdf.js/3.4.120/pdf.worker.min.js';
          resolve();
        };
        workerScript.onerror = reject;
        workerScript.src = 'https://cdnjs.cloudflare.com/ajax/libs/pdf.js/3.4.120/pdf.worker.min.js';
        document.head.appendChild(workerScript);
      };
      script.onerror = reject;
      document.head.appendChild(script);
    });
  }
}

// Define the custom element
if (!customElements.get('pdf-comparison')) {
  customElements.define('pdf-comparison', PdfComparison);
}

export default PdfComparison;
