import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["inProgressPanel", "endStagePanel", "castingVoteMember", "defaultDeadline", "deadlineCalculation"]

  stageTypeChanged(evt) {
    if (this.hasInProgressPanelTarget) {
      if (evt.target.value == "in_progress") {
        this.inProgressPanelTarget.classList.remove("d-none")
        this.inProgressPanelTarget.classList.add("d-block");
      } else {
        this.inProgressPanelTarget.classList.remove("d-block");
        this.inProgressPanelTarget.classList.add("d-none")
      }
    }
    if (this.hasEndStagePanelTarget) {
      if ((evt.target.value == "completed") || (evt.target.value == "cancelled")) {
        this.endStagePanelTarget.classList.remove("d-none")
        this.endStagePanelTarget.classList.add("d-block");
      } else {
        this.endStagePanelTarget.classList.remove("d-block");
        this.endStagePanelTarget.classList.add("d-none")
      }
    }
  }

  completionTypeChanged(evt) {
    if (this.hasCastingVoteMemberTarget) {
      if (evt.target.value == "casting_vote") {
        this.castingVoteMemberTarget.classList.remove("d-none")
        this.castingVoteMemberTarget.classList.add("d-block");
      } else {
        this.castingVoteMemberTarget.classList.remove("d-block");
        this.castingVoteMemberTarget.classList.add("d-none")
      }
    }
  }

  deadlineTypeChanged(evt) {
    if (this.hasDeadlineCalculationTarget) {
      if (evt.target.value == "calculated") {
        this.deadlineCalculationTarget.classList.remove("d-none")
        this.deadlineCalculationTarget.classList.add("d-block");
      } else {
        this.deadlineCalculationTarget.classList.add("d-none")
        this.deadlineCalculationTarget.classList.remove("d-block");
      }
    }
    if (this.hasDefaultDeadlineTarget) {
      if (evt.target.value == "calculated") {
        this.defaultDeadlineTarget.classList.add("d-none")
        this.defaultDeadlineTarget.classList.remove("d-block");
      } else {
        this.defaultDeadlineTarget.classList.remove("d-none")
        this.defaultDeadlineTarget.classList.add("d-block");
      }
    }
  }
}
