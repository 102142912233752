import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    this.element.scrollIntoView({
      block: 'end',
      inline: 'start',
      behaviour: 'smooth'
    })
  }

}