import { Controller } from "@hotwired/stimulus"
import dragula from "dragula"

export default class extends Controller {
  static values = { maximum: { type: Number, default: 1 } }
  connect() {
    this.dragula = dragula([this.element], {
      copy: false,
    })
    this.dragula.on("drop", (el, target, source, sibling) => {
      const form = document.getElementById(el.dataset.form)
      const input = form.querySelector("#dashboard_tile_position")
      let newPosition = this.maximumValue - 1
      const currentPosition = parseInt(el.dataset.position)
      if (sibling) {
        const siblingPosition = parseInt(sibling.dataset.position)
        if (siblingPosition == 1) {
          newPosition = 1
        } else {
          if (siblingPosition < currentPosition) {
            newPosition = siblingPosition
          } else {
            newPosition = siblingPosition - 1
          }
        }
      }
      input.value = newPosition
      form.requestSubmit()
    })
  }

}