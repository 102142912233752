import '@hotwired/turbo-rails'
import '@rails/activestorage'
import '@rails/request.js'
import 'trix'
import '@rails/actiontext'
import '../channels/index.js'
import '../controllers/index.js'
import '../javascripts/hyper'
import "../javascripts/pdf-comparison-element.js"
const images = require.context('../images', true)
const imagePath = name => images(name, true)
