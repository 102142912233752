import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  close(evt) {
    this.element.classList.add("is-moving-offscreen-left")
  }

  closeAndSubmit(evt) {
    evt.preventDefault()
    this.element.classList.add("is-blinking")
    setTimeout(() => {
      this.element.requestSubmit()
    }, 10)
  }

  disconnect() {
    this.element.classList.remove("is-blinking")
  }
}
