import { Controller } from "@hotwired/stimulus"
import Masonry from "masonry-layout/masonry"

export default class extends Controller {
  static values = { selector: String, width: String }

  connect() {
    this.masonry = new Masonry(this.element, {
      itemSelector: this.selectorValue,
      columnWidth: this.widthValue,
      percentPosition: true,
      horizontalOrder: false,
      gutter: 0,
    })
  }

  layout(event) {
    this.masonry.layout()
  }
}