import { Controller } from '@hotwired/stimulus';
import SignaturePad from '../javascripts/signature_pad';

export default class extends Controller {
	static targets = ['canvas', 'field', 'preview'];

	canvasTargetConnected(canvas) {
		this.signaturePadElement = new SignaturePad(this.canvasTarget);
	}

	canvasTargetDisconnected() {
		this.signaturePadElement = null;
	}

	save(evt) {
		evt.preventDefault();
		if (this.hasCanvasTarget && this.hasFieldTarget && this.signaturePadElement) {
			const data = this.signaturePadElement.toDataURL();
			this.fieldTarget.value = data;
			if (this.hasPreviewTarget) {
				this.previewTarget.src = data;
			}
			this.dispatch('updated');
		}
	}

	clear(evt) {
		evt.preventDefault();
		if (this.signaturePadElement) {
			this.signaturePadElement.clear();
		}
	}
}