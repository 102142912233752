import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static values = {
    elementId: String,
  };

  open(evt) {
    const drawer = document.getElementById(this.elementIdValue);
    if (drawer) {
      evt.preventDefault();
      drawer.show();
    }
  }

  close(evt) {
    const drawer = document.getElementById(this.elementIdValue);
    if (drawer) {
      evt.preventDefault();
      drawer.hide();
    }
  }
}