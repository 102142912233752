import { Controller } from "@hotwired/stimulus"
import { Turbo } from "@hotwired/turbo-rails"

export default class extends Controller {
  static values = { url: String, registrationUrl: String, unregistrationUrl: String }

  async perform(event) {
    event.target.disabled = true

    try {
      this.element.classList.add("is-blinking")

      let response = null
      if (event.target.dataset.submit) {
        response = await this.submitForm(event)
      } else {
        response = await this.submitCommand(event)
      }
      if (response) {
        if (response.ok) {
          const after = this.getAfterFrom(event.target)
          if (after == "reload") {
            this.redrawThis()
          } else if (after == "redirect") {
            const data = await response.json()
            this.redirectTo(data.url)
          } else if (after == "destroy") {
            this.removeElement()
          } else if (after == "reload_window") {
            this.reloadWindow()
          }
        } else {
          this.element.innerText = response.statusText
        }
      }
      this.element.classList.remove("is-blinking")
      
    } finally {
      event.target.disabled = false
    }
  }

  async submitForm(event) {
    const form = event.target.closest("form")
    if (form.reportValidity()) {
      const formContents = new FormData(form)
      const formData = new FormData
      formContents.forEach((value, name) => {
        const fileInput = form.querySelector(`[name="${name}"][type="file"]`)
        const fieldName = name.replace("[", "_").replace("]", "")
        const parameterName = `dynamic_component[${fieldName}]`
        if (fileInput) {
          formData.append(parameterName, fileInput.files[0])
        } else {
          formData.append(parameterName, value)
        }
      })
      return fetch(event.params.url, {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        headers: { "Accept": "application/json" },
        redirect: "follow",
        body: formData,
      })
    }
  }

  async submitCommand(event) {
    const params = this.resolve(event)
    return fetch(event.params.url, {
      method: "POST",
      mode: "cors",
      cache: "no-cache",
      headers: { "Content-Type": "application/json", "Accept": "application/json" },
      redirect: "follow",
      body: JSON.stringify(params),
    })
  }

  async saveExpanderState(event) {
    const element = (event.target.nodeName == "A") ? event.target : event.target.closest("a")
    const expanded = element.getAttribute("aria-expanded") === "true"
    const expanderId = element.dataset.bsTarget
    const url = element.dataset.expanderUrl
    return fetch(url, {
      method: "POST",
      mode: "cors",
      cache: "no-cache",
      headers: { "Content-Type": "application/json", "Accept": "application/json" },
      redirect: "follow",
      body: JSON.stringify({ "expanded": expanded, "expander_id": expanderId }),
    })
  }

  // go through each parameter and:
  //  if it starts with #, find the element with that ID and set the parameter to that element's value (or checked for checkboxes)
  //  if it starts with $, get the value of the given property from this element - eg: $position to get `this.position`
  //  otherwise just use the value provided
  resolve(event) {
    const parameters = event.params
    let resolved = {}
    Object.keys(parameters).forEach((key) => {
      const value = parameters[key].toString()
      if (value.startsWith("#")) {
        const el = this.element.querySelector(value)
        if (el.type === 'checkbox') {
          resolved[key] = el.checked
        } else {
          resolved[key] = el.value
        }
      } else if (value.startsWith("$")) {
        const propertyName = value.replace("$", "")
        if (event.target[propertyName]) {
          resolved[key] = event.target[propertyName]
        }
      } else {
        resolved[key] = parameters[key]
      }
    })
    return resolved
  }

  // Find the element that has the "after" data attribute
  getAfterFrom(element) {
    return element.dataset.after || this.getAfterFrom(element.parentElement)
  }

  async redrawThis() {
    if (this.element.dataset.reload == "turbo-frame") {
      this.reloadTurboFrame()
    } else {
      this.fetchAndRedraw()
    }
  }

  async reloadTurboFrame() {
    this.element.closest("turbo-frame").setAttribute("src", this.urlValue)
  }

  async fetchAndRedraw() {
    const response = await fetch(this.urlValue)
    if (response.ok) {
      const html = await response.text()
      this.element.outerHTML = html
    }
  }

  async removeElement() {
    this.element.remove()
  }

  async redirectTo(url) {
    this.element.classList.add("is-moving-offscreen-left")
    Turbo.visit(url)
  }

  async reloadWindow() {
    document.body.classList.add("is-updating")
    Turbo.visit(window.location.href, { action: "replace" })
  }
}