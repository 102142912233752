import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = { token: String, urn: String }

  connect() {
    const token = this.tokenValue
    const urn = `urn:${this.urnValue}`
    const element = this.element
    const options = {
      env: 'AutodeskProduction',
      api: 'derivativeV2_EU',
      getAccessToken: function (onTokenReady) {
        onTokenReady(token, 3600);
      }
    }
    Autodesk.Viewing.Initializer(options, function () {
      const viewer = new Autodesk.Viewing.GuiViewer3D(element);
      viewer.start();
      Autodesk.Viewing.Document.load(urn, onDocumentLoadSuccess, onDocumentLoadFailure);

      function onDocumentLoadSuccess(viewerDocument) {
        // Choose the default viewable - most likely a 3D model, rather than a 2D sheet.
        const defaultModel = viewerDocument.getRoot().getDefaultGeometry();
        viewer.loadDocumentNode(viewerDocument, defaultModel);
      }
      function onDocumentLoadFailure(code, message, errors) {
        console.error('Failed fetching Forge manifest', code, message, errors);
      }
    })
  }
}
