import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["tree", "folder"]
  static values = { folder: String }

  folderValueChanged() {
    this.updateFolderView()
  }

  treeTargetConnected() {
    this.updateFolderView()
  }

  // if we navigate to a new folder, then ensure the sidebar is updated accordingly
  async updateFolderView() {
    if (this.hasTreeTarget) {
      this.treeTarget.querySelectorAll("sl-tree-item").forEach((item) => { item.selected = false })
      if (this.folderValue) {
        let treeItem = document.getElementById(this.folderValue)
        if (treeItem) {
          treeItem.selected = true
        }
        while (treeItem) {
          treeItem.expanded = true
          treeItem = treeItem.parentElement.closest("sl-tree-item")
        }
      }
    }
  }

}