import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["spinner"]

  show() {
    if (this.hasSpinnerTarget) {
      this.spinnerTarget.classList.remove('d-none')
    }
  }
  hide() {
    if (this.hasSpinnerTarget) {
      this.spinnerTarget.classList.add('d-none')
    }
  }
}