// Hacked around version of https://github.com/mxcd/dragsnip

export class BoundingBox {
  constructor(style, canvas, callback, options) {
    this.style = style
    this.canvas = canvas
    this.onDragCompletion = callback

    // force the canvas style attributes to prevent scaling
    this.canvas.style.width = this.canvas.width + "px";
    this.canvas.style.height = this.canvas.height + "px";

    this.lastEventTime = 0;
    this.dragging = false;
    this.startPoint = {};
    this.endPoint = {};
    this.strokeColor = options && options.strokeColor ? options.strokeColor : '#666666';

    this.onMouseMoveHandler = this.onMouseMove.bind(this)
    this.onMouseDownHandler = this.onMouseDown.bind(this)
    this.onMouseUpHandler = this.onMouseUp.bind(this)

    this.canvas.addEventListener('mousemove', this.onMouseMoveHandler);
    this.canvas.addEventListener('mousedown', this.onMouseDownHandler);
    this.canvas.addEventListener('mouseup', this.onMouseUpHandler)
  }

  remove() {
    this.clearCanvas()
    this.dragging = false
    this.canvas.removeEventListener('mousemove', this.onMouseMoveHandler);
    this.canvas.removeEventListener('mousedown', this.onMouseDownHander);
    this.canvas.removeEventListener('mouseup', this.onMouseUpHandler)
    this.canvas = null
  }

  onMouseDown(evt) {
    if ((this.canvas != null) && (!this.dragging)) {
      this.dragging = true
      this.clearCanvas()
      this.startPoint = this.getAbsoluteCoordinatesFrom(evt)
      this.endPoint = this.startPoint
      this.lastEventTime = new Date()
    }
  }

  onMouseUp(evt) {
    if ((this.canvas != null) && (this.dragging && !this.accidentalClick())) {
      this.dragging = false
      this.endPoint = this.getAbsoluteCoordinatesFrom(evt);
      this.drawGuide()
      this.onDragCompletion(this.startPoint, this.endPoint);
      this.clearCanvas()
      this.lastEventTime = new Date();
    }
  }

  onMouseMove(evt) {
    if ((this.canvas != null) && this.dragging) {
      this.endPoint = this.getAbsoluteCoordinatesFrom(evt);
      this.drawGuide()
    }
  }

  accidentalClick() {
    return ((new Date() - this.lastEventTime) < 500)
  }

  clearCanvas() {
    const ctx = this.canvas.getContext("2d");
    ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
  }

  drawGuide() {
    if ((this.style == 'arrow') || (this.style == 'ruler')) {
      this.drawLine()
    } else {
      this.drawRect()
    }
  }

  drawRect() {
    this.clearCanvas();
    const ctx = this.canvas.getContext("2d");
    const width = this.endPoint.x - this.startPoint.x
    const height = this.endPoint.y - this.startPoint.y
    ctx.strokeStyle = this.strokeColor;
    ctx.lineWidth = 1;
    ctx.strokeRect(this.startPoint.x, this.startPoint.y, width, height);
  }

  drawLine() {
    this.clearCanvas()

    const ctx = this.canvas.getContext("2d");
    const width = this.endPoint.x - this.startPoint.x
    const height = this.endPoint.y - this.startPoint.y

    const angle = Math.atan2(height, width)
    const length = Math.sqrt(width * width + height * height)

    ctx.lineWidth = 1
    ctx.strokeStyle = this.strokeColor;
    ctx.translate(this.startPoint.x, this.startPoint.y)
    ctx.rotate(angle)
    ctx.beginPath()
    ctx.moveTo(10, -10)
    ctx.lineTo(0, 0)
    ctx.lineTo(10, 10)
    ctx.moveTo(0, 0)
    ctx.lineTo(length, 0)
    ctx.moveTo(10, -10)
    ctx.lineTo(0, 0)
    ctx.lineTo(10, 10)
    ctx.stroke()
    ctx.setTransform(1, 0, 0, 1, 0, 0)
  }

  getAbsoluteCoordinatesFrom(evt) {
    return { x: evt.offsetX, y: evt.offsetY }
  }

}
