import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  async renderWithMorphdom(event) {
    const defaultRenderer = event.detail.render
    event.detail.render = async (streamElement) => {
      const existingElement = document.getElementById(streamElement.target)
      if (existingElement) {
        if (existingElement.dataset.morph == "true") {
          const existingContents = existingElement
          const newContents = streamElement.firstElementChild.innerHTML
          if (existingContents) {
            Idiomorph.morph(existingContents, newContents)
          }
        } else {
          defaultRenderer(streamElement)
        }
      }
    }
  }
}
