import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["formatField", "downloadPanel", "downloadExpander"]

  downloadAuditTrail(evt) {
    evt.preventDefault();
    this.doDownload()
    }

  async doDownload() {
    this.setFormToDownloadMode()
    await this.performDownload()
    this.setFormToViewMode()
  }

  setFormToDownloadMode() {
    this.element.dataset.turboFrame = "_top"
    this.formatFieldTarget.value = "csv"
  }

  async performDownload() {
    const formData = new FormData(this.element)
    const response = await fetch(this.element.action, {
      method: "PATCH",
      redirect: "manual",
      body: formData
    })
    const data = await response.blob()
    const link = document.createElement("a")
    link.download = "audit.csv"
    link.href = window.URL.createObjectURL(data)
    link.click()
    link.remove()
  }

  setFormToViewMode() {
    this.element.dataset.turboFrame = null
    this.formatFieldTarget.value = "html"
    this.downloadExpanderTarget.setAttribute("aria-expanded", false)
    this.downloadPanelTarget.classList.remove("show")
  }
}