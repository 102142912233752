import { Controller} from "@hotwired/stimulus"
import {BoundingBox} from "../javascripts/boundingBox"
import MutexPromise from "mutex-promise"
import "../javascripts/colorPick.js"

export default class extends Controller {
  static values = {
    documentUrl: String,
    pageNumber: {
      type: Number,
      default: 1
    },
    scale: {
      type: Number,
      default: 0.5
    },
    user: {
      type: String,
      default: ''
    },
    colour: {
      type: String,
      default: '#333333'
    },
    showMarkup: {
      type: Boolean,
      default: true
    }
  }
  static targets = ["canvasContainer", "pdfCanvas", "annotationsCanvas", "dragCanvas", "annotationForm", "spinner", "markupControls", "editButton", "cancelButton", "pageNavigationButton", "markupContainer", "saveAnnotationButton", "colourPicker", "zoomLevelIndicator", "addMessageExpander"]

  // event handlers and properties
  initialize() {
    pdfjsLib.GlobalWorkerOptions.workerSrc = '//cdn.jsdelivr.net/npm/pdfjs-dist@3.11.174/build/pdf.worker.js';
    MutexPromise.Promise = Promise
  }
  connect() {
    this.documentLock = new MutexPromise(this.element.id + '-document-lock')
    this.pageLock = new MutexPromise(this.element.id + '-page-lock')
    this.annotationLock = new MutexPromise(this.element.id + '-annotation-lock')
    this.shape = 'cloud'
    this.pdfDocument = null
    this.locked = false
    this.dragHandler = null
  }
  colourPickerTargetConnected(element) {
    element.value = this.colourValue
  }
  annotationsCanvasTargetConnected() {
    this.loadPdf()
  }
  zoomLevelIndicatorTargetConnected() {
    this.updateZoomLevelIndicator()
  }
  markupContainerTargetConnected(container) {
    if (this.annotationLock && this.hasAnnotationsCanvasTarget) {
      const markup = JSON.parse(container.innerHTML)
      this.drawAnnotation(markup)
    }
  }
  markupContainerTargetDisconnected() {
    if (this.annotationLock && this.hasAnnotationsCanvasTarget) {
      this.clearAnnotations()
      this.renderAnnotations()
    }
  }
  pageNumberValueChanged() {
    this.renderDocument()
  }
  scaleValueChanged() {
    this.renderDocument()
    this.updateZoomLevelIndicator()
  }
  nextPage(evt) {
    evt.preventDefault()
    this.pageNumberValue = (this.pageNumberValue < this.numberOfPages()) ? this.pageNumberValue + 1 : 1
  }
  previousPage(evt) {
    evt.preventDefault()
    this.pageNumberValue = (this.pageNumberValue > 1) ? this.pageNumberValue - 1 : this.numberOfPages()
  }
  zoomIn(evt) {
    evt.preventDefault()
    this.scaleValue += 0.1
  }
  zoomOut(evt) {
    evt.preventDefault()
    this.scaleValue -= 0.1
  }
  showMarkupValueChanged(newValue) {
    if (this.hasMarkupControlsTarget) {
      if (newValue) {
        this.markupControlsTarget.classList.remove('d-none')
      } else {
        this.markupControlsTarget.classList.add("d-none")
      }
    }
    this.renderDocument()
  }
  startDrawingCloud(evt) {
    this.startEditing(evt, 'cloud')
  }
  startDrawingArrow(evt) {
    this.startEditing(evt, 'arrow')
  }
  startDrawingBlock(evt) {
    this.startEditing(evt, 'block')
  }
  startDrawingRuler(evt) {
    this.startEditing(evt, 'ruler')
  }
  startEditing(evt, shape) {
    this.shape = shape
    evt.preventDefault()
    this.addDragHandler()
  }
  cancelEdits(evt) {
    evt.preventDefault()
    this.hideAnnotationForm()
    this.removeDragHandler()
  }
  saveEdits(evt) {
    evt.preventDefault()
    this.submitAnnotationForm()
    this.removeDragHandler()
  }
  updateColour(evt) {
    this.colourValue = evt.target.value
  }
  cancelNewMessage(evt) {
    evt.preventDefault()
    if (this.hasAddMessageExpanderTarget) {
      this.addMessageExpanderTarget.click(evt)
    }
  }
  toggleMarkup(evt) {
    evt.preventDefault()
    this.showMarkupValue = !this.showMarkupValue
  }
  toggleAnnotation(evt) {
    const annotationId = evt.target.dataset.annotationId
    const form = evt.target.closest("form")
    const container = document.getElementById(annotationId)
    const markup = JSON.parse(container.innerHTML)
    markup.hidden = !evt.target.checked
    container.innerHTML = JSON.stringify(markup)
    form.requestSubmit()
    this.clearAnnotations()
    this.renderAnnotations()
  }
  numberOfPages() {
    if (this.pdfDocument == null) {
      return 0
    } else {
      return this.pdfDocument.numPages
    }
  }

  // Load the PDF from the URL and then render it and its annotations
  async loadPdf() {
    if (this.hasDocumentUrlValue && this.hasAnnotationsCanvasTarget && this.hasPdfCanvasTarget) {
      this.pdfDocument = await pdfjsLib.getDocument({
        url: this.documentUrlValue
      }).promise
      this.showOrHidePageNavigationButtons()
      await this.renderDocument()
    }
  }
  // Prepare the canvases and then draw the page and annotations
  async renderDocument() {
    if (this.pdfDocument != null) {
      await this.documentLock.promise()
      this.documentLock.lock()
      this.showSpinner()
      this.removeDragHandler()

      const page = await this.pdfDocument.getPage(this.pageNumberValue)
      const renderContext = this.getRenderContextFor(page)
      this.resizeElement(this.canvasContainerTarget, renderContext.size)
      this.resizeElement(this.pdfCanvasTarget, renderContext.size)
      this.resizeElement(this.annotationsCanvasTarget, renderContext.size)
      this.resizeElement(this.dragCanvasTarget, renderContext.size)

      await Promise.all([this.renderPage(), this.renderAnnotations()])

      this.hideSpinner()
      this.documentLock.unlock()
    }
  }
  // Render the current page
  async renderPage() {
    await this.pageLock.promise()
    this.pageLock.lock()
    const page = await this.pdfDocument.getPage(this.pageNumberValue)
    const renderContext = this.getRenderContextFor(page)
    await page.render(renderContext)
    this.pageLock.unlock()
  }
  // Render the annotations
  async clearAnnotations() {
    await this.annotationLock.promise()
    this.annotationLock.lock()
    const canvas = this.annotationsCanvasTarget
    const context = canvas.getContext('2d')
    context.clearRect(0, 0, canvas.width, canvas.height);
    this.annotationLock.unlock()
  }
  async renderAnnotations() {
    if (this.showMarkupValue) {
      this.markupContainerTargets.forEach(container => {
        const markup = JSON.parse(container.innerHTML)
        this.drawAnnotation(markup)
      })
    }
  }
  async drawAnnotation(markup) {
    if ((markup.page == this.pageNumberValue) && !markup.hidden) {
      await this.annotationLock.promise()
      this.annotationLock.lock()
      const canvas = this.annotationsCanvasTarget
      const context = canvas.getContext('2d')
      const start = this.toAbsoluteCoordinates(markup.start, canvas)
      const end = this.toAbsoluteCoordinates(markup.end, canvas)
      const width = end.x - start.x
      const height = end.y - start.y
      const shape = markup.shape
      if (shape == 'arrow') {
        this.drawArrow(context, markup, start, end, width, height)
      } else if (shape == 'cloud') {
        this.drawCloud(context, markup, start, end, width, height)
      } else if (shape == 'block') {
        this.drawBlock(context, markup, start, end, width, height)
      } else if (shape == 'ruler') {
        this.drawRuler(context, markup, start, end, width, height)
      }
      this.annotationLock.unlock()
    }
  }
  // text helper routines
  collapseWhiteSpace(value) {
    return (value.trim().replace(/\s+/g, " "));
  }
  breakTextIntoLines(context, text, maxWidth) {
    const words = this.collapseWhiteSpace(text).split(" ")
    const lines = []
    let line = ""
    words.forEach(word => {
      const testLine = line + word + " "
      const testWidth = context.measureText(testLine).width
      if (testWidth > maxWidth) {
        lines.push(line)
        line = word + " "
      } else {
        line = testLine
      }
    })
    lines.push(line)
    return lines
  }
  // drawing routines
  writeCaption(context, colour, caption, position) {
    if (caption != "") {
      context.textBaseline = 'top'
      context.font = '8pt sans-serif'
      context.lineWidth = 2
      const textMetrics = context.measureText(caption)
      const height = textMetrics.fontBoundingBoxAscent + textMetrics.fontBoundingBoxDescent + 6
      const maxWidth = context.canvas.width / 12
      const lines = this.breakTextIntoLines(context, caption, maxWidth)
      lines.forEach((line, index) => {
        const w = context.measureText(line).width;
        const x = position.x - (w / 2)
        const y = (position.y + (index * height)) - 20

        context.fillStyle = "RGBA(255, 255, 255, 0.8)"
        context.fillRect(x - 10, y, w + 20, height + 5);

        context.fillStyle = colour
        context.fillText(line, x, y + 10)
      })
    }
  }
  drawCloud(context, markup, p1, p2, w, h) {
    const start = this.minOf(p1, p2)
    const end = this.maxOf(p1, p2)
    let x = start.x
    let y = start.y

    context.beginPath()
    context.moveTo(start.x, start.y);
    // top edge
    for (x = start.x; x < end.x; x += 8) {
      let cp1 = {
        x: x + 4,
        y: start.y
      }
      let cp2 = {
        x: x + 4,
        y: start.y - 20
      }
      context.bezierCurveTo(cp1.x, cp1.y, cp2.x, cp2.y, x, start.y)
    }
    // right edge
    for (y = start.y; y < end.y; y += 8) {
      let cp1 = {
        x: end.x,
        y: y + 4
      }
      let cp2 = {
        x: end.x + 20,
        y: y + 4
      }
      context.bezierCurveTo(cp1.x, cp1.y, cp2.x, cp2.y, end.x, y)
    }
    // bottom edge
    for (x = end.x; x > start.x; x -= 8) {
      let cp1 = {
        x: x - 4,
        y: end.y
      }
      let cp2 = {
        x: x - 4,
        y: end.y + 20
      }
      context.bezierCurveTo(cp1.x, cp1.y, cp2.x, cp2.y, x, end.y)
    }
    // left edge
    for (y = end.y; y > start.y; y -= 8) {
      let cp1 = {
        x: start.x,
        y: y - 4
      }
      let cp2 = {
        x: start.x - 20,
        y: y - 4
      }
      context.bezierCurveTo(cp1.x, cp1.y, cp2.x, cp2.y, start.x, y)
    }
    context.closePath();
    context.lineWidth = 2
    context.strokeStyle = markup.colour
    context.fillStyle = 'transparent'
    context.stroke()
    const caption = `${markup.comment}: ${markup.user}`
    this.writeCaption(context, markup.colour, caption, end)
  }
  drawBlock(context, markup, p1, p2, w, h) {
    const start = this.minOf(p1, p2)
    const transparentColour = markup.colour + '80'

    context.beginPath()
    context.lineWidth = 6
    context.fillStyle = transparentColour
    context.fillRect(start.x, start.y, w, h)
    context.stroke()

    const middle = {
      x: start.x + w / 2,
      y: start.y + h / 2
    }
    const caption = `${markup.comment}: ${markup.user}`
    this.writeCaption(context, markup.colour, caption, middle)
  }
  drawArrow(context, markup, s, e, w, h) {
    const angle = Math.atan2(h, w)
    const length = Math.sqrt(w * w + h * h)

    context.lineWidth = 3
    context.lineCap = 'round'
    context.strokeStyle = markup.colour
    context.translate(s.x, s.y)
    context.rotate(angle)
    context.beginPath()
    context.moveTo(0, 0)
    context.lineTo(length, 0)
    context.moveTo(10, -10)
    context.lineTo(0, 0)
    context.lineTo(10, 10)
    context.stroke()
    context.setTransform(1, 0, 0, 1, 0, 0)

    const caption = `${markup.comment}: ${markup.user}`
    this.writeCaption(context, markup.colour, caption, e)
  }
  drawRuler(context, markup, s, e, w, h) {
    const angle = Math.atan2(h, w)
    const length = Math.sqrt(w * w + h * h)

    context.lineWidth = 6
    context.lineCap = 'round'
    context.strokeStyle = markup.colour
    context.translate(s.x, s.y)
    context.rotate(angle)
    context.beginPath()

    context.moveTo(0, 0)
    context.lineTo(length, 0)

    context.moveTo(0, -10)
    context.lineTo(0, 10)

    context.moveTo(length, -10)
    context.lineTo(length, 10)

    context.stroke()
    context.setTransform(1, 0, 0, 1, 0, 0)
    const middle = {
      x: s.x + w / 2,
      y: s.y + h / 2
    }
    const caption = `${markup.comment}: ${markup.user}`
    this.writeCaption(context, markup.colour, caption, middle)
  }
  // helpers to prepare the canvases
  getRenderContextFor(page) {
    const scale = this.scaleValue
    const canvas = this.pdfCanvasTarget
    const viewport = page.getViewport({
      scale
    })
    const outputScale = window.devicePixelRatio || 1
    const transform = (outputScale !== 1) ? [outputScale, 0, 0, outputScale, 0, 0] : null;
    const context = canvas.getContext('2d')

    const width = Math.floor(viewport.width * outputScale)
    const height = Math.floor(viewport.height * outputScale)
    const size = {
      width: width,
      height: height,
      styleWidth: width + "px",
      styleHeight: height + "px",
    }
    return {
      canvasContext: context,
      transform: transform,
      viewport: viewport,
      size: size
    }
  }
  resizeElement(element, size) {
    element.width = size.width
    element.height = size.height
    element.style.width = size.styleWidth
    element.style.height = size.styleHeight
  }
  // helpers for drawing routines
  toRelativeCoordinates(point, canvas) {
    const width = canvas.clientWidth
    const height = canvas.clientHeight
    point.x = (point.x / width) * 100.0
    point.y = (point.y / height) * 100.0
    return point
  }
  toAbsoluteCoordinates(point, canvas) {
    const width = canvas.clientWidth
    const height = canvas.clientHeight
    point.x = (point.x / 100.0) * width
    point.y = (point.y / 100.0) * height
    return point
  }
  minOf(p1, p2) {
    return {
      x: (p1.x < p2.x ? p1.x : p2.x),
      y: (p1.y < p2.y ? p1.y : p2.y)
    }
  }
  maxOf(p1, p2) {
    return {
      x: (p1.x > p2.x ? p1.x : p2.x),
      y: (p1.y > p2.y ? p1.y : p2.y)
    }
  }
  // update document navigation bar
  async showOrHidePageNavigationButtons() {
    if (this.numberOfPages() < 2) {
      this.pageNavigationButtonTargets.forEach((el) => {
        el.classList.add('d-none')
      })
    }
  }
  async updateZoomLevelIndicator() {
    if (this.hasZoomLevelIndicatorTarget) {
      this.zoomLevelIndicatorTarget.textContent = this.scaleValue.toFixed(1).toString()
    }
  }
  async showSpinner() {
    if (this.hasSpinnerTarget) {
      this.spinnerTarget.classList.remove('d-none')
    }
  }
  async hideSpinner() {
    if (this.hasSpinnerTarget) {
      this.spinnerTarget.classList.add('d-none')
    }
  }
  // get annotation details from the user
  showAnnotationForm(markup) {
    markup.page = this.pageNumberValue
    const formContainer = this.annotationFormTarget
    formContainer.querySelector('#document_revision_markup').value = JSON.stringify(markup)
    formContainer.querySelector('#document_revision_comment').value = ''
    formContainer.show()
    formContainer.querySelector('#document_revision_comment').focus()
  }
  hideAnnotationForm() {
    const formContainer = this.annotationFormTarget
    formContainer.querySelector('#document_revision_comment').value = ''
    formContainer.querySelector('#document_revision_markup').value = ''
    formContainer.hide()
  }
  submitAnnotationForm() {
    const formContainer = this.annotationFormTarget
    const form = formContainer.querySelector('form')
    const markupInput = form.querySelector('#document_revision_markup')
    const commentInput = form.querySelector('#document_revision_comment')
    const markup = JSON.parse(markupInput.value)
    markup.comment = commentInput.value
    markup.user = this.userValue
    markup.colour = this.colourValue
    markup.shape = this.shape
    markupInput.value = JSON.stringify(markup)
    if (this.hasSaveAnnotationButtonTarget) {
      const click = new MouseEvent('click', {
        view: window,
        bubbles: true,
        cancelable: true
      })
      this.saveAnnotationButtonTarget.dispatchEvent(click)
    } else {
      form.submit()
    }
    formContainer.hide()
  }
  // drag handlers to get the bounds of the shape being drawn
  addDragHandler() {
    this.dragHandler = new BoundingBox(this.shape, this.dragCanvasTarget, this.dragComplete.bind(this))
    this.dragCanvasTarget.classList.remove('d-none')
    this.editButtonTargets.forEach((el) => {
      el.classList.add('d-none')
    })
    if (this.hasCancelButtonTarget) {
      this.cancelButtonTarget.classList.remove('d-none')
    }
    if (this.hasColourPickerTarget) {
      this.colourPickerTarget.classList.remove('d-none')
    }
  }
  removeDragHandler() {
    if (this.dragHandler != null) {
      this.dragHandler.remove()
      this.dragHandler = null
    }
    this.dragCanvasTarget.classList.add('d-none')
    this.editButtonTargets.forEach((el) => {
      el.classList.remove('d-none')
    })
    if (this.hasCancelButtonTarget) {
      this.cancelButtonTarget.classList.add('d-none')
    }
    if (this.hasColourPickerTarget) {
      this.colourPickerTarget.classList.add('d-none')
    }
  }
  dragComplete(dragStart, dragEnd) {
    const canvas = this.dragCanvasTarget
    const start = this.toRelativeCoordinates(dragStart, canvas)
    const end = this.toRelativeCoordinates(dragEnd, canvas)
    const markup = {
      start: start,
      end: end,
      comment: ""
    }
    this.showAnnotationForm(markup)
  }
}
