import { Controller } from "@hotwired/stimulus"
export default class extends Controller {
  static values = {
    interval: { type: Number, default: 1000 }
  }

  connect() {
    let targetFrame = this.element.dataset.turboFrame
    let href = this.element.getAttribute('href')
    const interval = this.intervalValue
    if (targetFrame == undefined) {
      this.timer = setTimeout(() => {
        this.element.click()
      }, interval)
    } else {
      this.timer = setTimeout(() => {
        let frame = document.querySelector(`turbo-frame#${targetFrame}`)
        frame.src = href
        frame.reload()
      }, interval)
    }
  }

  disconnect() {
    if (this.timer) {
      clearTimeout(this.timer)
    }
  }
}